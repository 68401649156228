import { FormWrapper } from "../../../Common/FormWrapper";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { LoanDto } from "../../../../types/api";

interface IProlongationRequestFinalProps {
  loan: LoanDto;
}

export const ProlongationRequestFinal: FC<IProlongationRequestFinalProps> = ({
  loan,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = () => {
    navigate(`/${loan.customerId}/overview`);
    navigate(0);
  };

  return (
    <FormWrapper
      onSubmit={onSubmit}
      currentStep={3}
      isLastStep
      title=""
      buttonText={t("prolongation-form-return-to-application")}
    >
      <Typography>{t("prolongation-form-success-processing")}</Typography>
      <br />
      <br />
    </FormWrapper>
  );
};
