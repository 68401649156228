import React, { FC } from "react";
import styles from "./StatusBanner.module.scss";
import { RobotImage } from "../UI/RobotImage";
import Button, { IButtonProps } from "../UI/Button";
import LinkButton from "../UI/LinkButton";

interface IStatusBannerProps {
  title: string;
  content: React.ReactNode;
  contentFooter?: React.ReactNode;
  actionButton?: IButtonProps | null;
  altAction?: {
    text: React.ReactNode;
    icon?: React.ReactNode;
    onClick: () => void;
  };
  showRobot?: boolean;
}

const StatusBanner: FC<IStatusBannerProps> = ({
  title,
  content,
  contentFooter,
  actionButton,
  altAction,
  showRobot = true,
}) => {
  return (
    <div className={styles.offer}>
      <div className={styles["offer-body"]}>
        {showRobot && <RobotImage />}
        <div className={styles["body-info-wrapper"]}>
          <div className={styles["header"]}>
            <h2>{title}</h2>
            {altAction && (
              <LinkButton
                className={styles["alt-action"]}
                onClick={altAction.onClick}
              >
                {altAction.icon && (
                  <span className={styles["alt-action-icon"]}>
                    {altAction.icon}
                  </span>
                )}
                {altAction.text}
              </LinkButton>
            )}
          </div>
          <div className={styles["body-info"]}>
            <span>{content}</span>
            {actionButton && (
              <Button
                className="overview-banner"
                type="button"
                {...actionButton}
              />
            )}
          </div>
        </div>
      </div>

      {contentFooter ? (
        <div className={styles["footer"]}>{contentFooter}</div>
      ) : null}
    </div>
  );
};

export default StatusBanner;
