import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import classes from "./Button.module.scss";

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
}

const Button: FC<IButtonProps> = ({
  children,
  className,
  onClick,
  disabled,
  type,
  id,
}) => {
  let c = "";
  if (className) {
    className.split(" ").forEach((e) => {
      c += classes[e] + " ";
    });
  }

  return (
    <button
      type={type || "button"}
      className={`${classes.button} ${c}`}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      {children}
    </button>
  );
};

export default Button;
