import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import Button from "../UI/Button";
import PieChart from "./PieChart";
import { formatDate, formatMoney, formatDayString } from "../../utils/format";
import { getDatesInRange } from "../../utils/date";

import styles from "./CurrentFinancing.module.scss";
import { IsoCurrencyCode, LoanDto } from "../../types/api";

interface ICurrentFinancingProps {
  showOverdueInfo?: boolean;
  className?: string;
  customerId: string;
  loan: LoanDto;
}

const CurrentFinancing: FC<ICurrentFinancingProps> = ({
  showOverdueInfo,
  className,
  customerId,
  loan,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    instalments,
    paidPerc,
    totalPaid,
    prolongationEligible,
    currency,
    prolongationFee,
    activeProlongation,
  } = loan ?? {};

  const instalmentsPlanned =
    instalments?.filter((i) =>
      ["PLANNED", "OVERDUE"].includes(i.state ?? "")
    ) ?? [];

  const instalmentsPaid = instalments?.filter((i) => i.state === "PAID") ?? [];

  let overdueDays = 0;
  if (
    showOverdueInfo &&
    instalmentsPlanned?.length &&
    instalmentsPlanned.length > 0 &&
    instalmentsPlanned[0].state === "OVERDUE"
  ) {
    const today = new Date();
    const d2 = new Date(instalmentsPlanned[0].dueDate ?? "");
    const days = getDatesInRange(d2, today);
    overdueDays = days.length - 1;
  }

  const onProlongationClick = () => {
    // window.open(
    //   'https://m677yzcvksl.typeform.com/to/K6F3mSeX#status=New',
    //   '_blank'
    // );

    if (
      activeProlongation &&
      activeProlongation.state === "ADDENDUM_SENT" &&
      activeProlongation.contractSignUrl
    ) {
      window.open(activeProlongation.contractSignUrl, "_blank");
      return;
    }

    navigate(`/${customerId}/prolongation`, { state: { loan } });
  };

  const getProlongationButtonLabel = (): string => {
    if (activeProlongation) {
      return activeProlongation.state === "NEW"
        ? "prolongation-action-pay-fee"
        : activeProlongation.state === "ADDENDUM_SENT"
        ? "prolongation-action-sign-addendum"
        : "prolongation-action-label";
    }

    return prolongationEligible
      ? "prolongation-action-label"
      : "prolongation-request-support";
  };

  return (
    <div
      className={`${styles["current-financing"]} ${styles[className ?? ""]}`}
    >
      {paidPerc && <PieChart value={paidPerc} />}

      <div className={styles.payment}>
        <div className={styles["header-wrapper"]}>
          <h3>{t("installment-overview")}</h3>

          {totalPaid && totalPaid > 0 && (
            <div className={styles["paid-wrapper"]}>
              <span>{t("installment-total-paid")}</span>
              <span className={styles.value}>
                {formatMoney(totalPaid, currency as IsoCurrencyCode, false)}
              </span>
            </div>
          )}
        </div>

        {instalmentsPaid.length > 0 && (
          <>
            <div className={styles.header}>
              <span className={styles.date}>{t("installment-due-date")}</span>
              <span className={styles.paid}>{t("installment-paid")}</span>
              <span className={styles.value}>{t("installment-amount")}</span>
            </div>
            <div className={`${styles["pay-list"]} ${styles.paid}`}>
              {instalmentsPaid.map((i, index) => {
                return (
                  <div className={styles.line} key={index}>
                    <span className={styles["line-wrapper"]}>
                      <span className={styles.date}>
                        {formatDate(i.dueDate)}
                      </span>
                      <span className={styles.text}>{` - ${index + 1}. ${t(
                        "installment-installment"
                      )}`}</span>
                    </span>
                    <span
                      className={`${styles["line-separator"]} ${styles["detail-info"]}`}
                    ></span>
                    <span className={`${styles.date} ${styles["detail-info"]}`}>
                      {formatDate(i.paidDate)}
                    </span>
                    <span className={styles["line-separator"]}></span>
                    <span className={styles.value}>
                      {formatMoney(i.total, currency as IsoCurrencyCode, false)}
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {instalmentsPlanned?.length && instalmentsPlanned.length > 0 && (
          <div className={styles["pay-list"]}>
            {instalmentsPlanned.map((i, index) => {
              return (
                <div key={index}>
                  <div className={styles.line}>
                    <span>
                      <span className={styles.date}>
                        {formatDate(i.dueDate)}
                      </span>
                      <span className={styles.text}>{` - ${
                        instalmentsPaid.length + index + 1
                      }. ${t("installment-installment")}`}</span>
                    </span>
                    <span className={styles["line-separator"]}></span>
                    <span className={styles.value}>
                      {formatMoney(i.total, currency as IsoCurrencyCode, false)}
                    </span>
                  </div>

                  {showOverdueInfo && index === 0 && i.state === "OVERDUE" && (
                    <div className={styles["overdue-info"]}>
                      <span className={styles.info}>
                        {t("installment-overdue-days")}{" "}
                        {formatDayString(overdueDays)}
                      </span>

                      {overdueDays >= 1 && overdueDays <= 4 && (
                        <span>{t("installment-overdue-days-info")}</span>
                      )}

                      {overdueDays >= 5 && (
                        <span>
                          {t("installment-overdue-days-info")}{" "}
                          {t("installment-overdue-days-info-alt")}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        <div className={styles.prolongation}>
          <Button onClick={onProlongationClick}>
            {t(getProlongationButtonLabel())}{" "}
          </Button>
          {prolongationEligible && prolongationFee && prolongationFee > 0 && (
            <span>
              {t("installment-prolongation-fee")}{" "}
              {formatMoney(prolongationFee, currency as IsoCurrencyCode, false)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentFinancing;
