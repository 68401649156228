import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import styles from "./LinkButton.module.scss";

interface ILinkButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  className?: string;
  children: ReactNode;
}

const LinkButton: FC<ILinkButtonProps> = ({
  onClick,
  className,
  children,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.linkButton} ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default LinkButton;
