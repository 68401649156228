import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoanDto, ProlongationPreviewDto } from '../../../types/api';
import { ProlongationHelpForm } from './steps/ProlongationHelpForm.tsx';
import { ProlongationHelpFinal } from './steps/ProlongationHelpFinal';
import { Box, Grid } from '@mui/material';
import { RobotImage } from '../../UI/RobotImage';
import { RegistrationProgressBar } from '../Registration/registration_components/RegistrationProgressBar';
import { useScreenSize } from '../../../hooks';
import { RegistrationProgressBarMobile } from '../Registration/registration_components/RegistrationProgressBarMobile';
import { ProlongationRequestForm } from './steps/ProlongationRequestForm';
import axios from '../../../utils/axios';
import { addSnackbar } from '../../../store/slices/snackbarsSlice';
import { useAppDispatch } from '../../../selectors/selectors';
import { parseApiError } from '../../../utils/utils';
import { useLoading } from '../../../context/loading-context';
import Loading from '../../UI/Loading';
import { ProlongationRequestInformation } from './steps/ProlongationRequestInformation';
import { ProlongationRequestFinal } from './steps/ProlongationRequestFinal';

interface IProlongationProps {
  // loan: LoanDto; // No need for props, as the loan will be passed via navigation state
}

type Steps = {
  [key: number]: ReactNode;
};

const Prolongation: FC<IProlongationProps> = () => {
  const location = useLocation();
  const { loan } = location.state as { loan: LoanDto };
  const { isMediumScreen } = useScreenSize();
  const [currentStep, setCurrentStep] = useState(
    loan?.activeProlongation ? 2 : 1
  );
  const dispatch = useAppDispatch();
  const { loading, setLoading } = useLoading();

  const [prolongationPreview, setProlongationPreview] =
    useState<ProlongationPreviewDto | null>(null);

  const isProlongationEligable =
    loan?.prolongationEligible || loan?.activeProlongation;

  useEffect(() => {
    const fetchProlongationPreview = async () => {
      setLoading(true);
      try {
        const response = await axios.get<ProlongationPreviewDto>(
          `loans/${loan.id}/prolongations/preview`
        );
        setProlongationPreview(response.data);
      } catch (error) {
        const { message } = parseApiError(error);

        dispatch(
          addSnackbar({
            message: message,
            variant: 'error',
          })
        );
      } finally {
        setLoading(false);
      }
    };

    if (isProlongationEligable) {
      fetchProlongationPreview();
    }
  }, [loan.id, loan?.prolongationEligible, loan?.activeProlongation]);

  const isFirstStep = currentStep === 1;

  const isLastStep = isProlongationEligable
    ? currentStep === 3
    : currentStep === 2;

  const goNextPage = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const goBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const prolongationFormNav = isProlongationEligable
    ? 'prolongationForm'
    : 'prolongationTextForm';

  const progressStep = Math.ceil(
    (currentStep / (isProlongationEligable ? 3 : 2)) * 100
  );

  const prolongationSteps: {
    prolongationForm: Steps;
    prolongationTextForm: Steps;
  } = {
    prolongationForm: {
      1: (
        <ProlongationRequestForm
          loan={loan}
          prolongationPreview={prolongationPreview}
          next={goNextPage}
        />
      ),
      2: (
        <ProlongationRequestInformation
          next={goNextPage}
          loan={loan}
          prolongationPreview={prolongationPreview}
        />
      ),
      3: <ProlongationRequestFinal loan={loan} />,
    },
    prolongationTextForm: {
      1: <ProlongationHelpForm next={goNextPage} />,
      2: <ProlongationHelpFinal />,
    },
  };

  return (
    <>
      {isMediumScreen ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: 'white',
              borderRadius: '20px',
              p: '2rem',
              m: '2rem',
              width: '72.5rem',
            }}
          >
            <Grid
              md={3}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <RobotImage style={{ height: '250px', width: '200px' }} />
            </Grid>
            <Grid
              md={9}
              xs={12}
              sx={{
                borderRadius: '.5rem',
                border: '1px solid #DEDEDE',
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              {prolongationSteps[prolongationFormNav][currentStep]}
            </Grid>
            <Grid sm={3} alignSelf="end" textAlign="right">
              {/* {displayBackButton ? <BackButton onClick={goBack} /> : null} */}
            </Grid>
            <Grid sm={!isFirstStep ? 9 : 12} md={9} pr={0} pl={0}>
              <RegistrationProgressBar
                isLastStep={isLastStep}
                progressStep={progressStep}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            flexBasis: '100%',
            maxWidth: '100%',
            position: 'relative',
            backgroundColor: 'white',
            borderRadius: '20px',
            margin: '16px 1rem 1rem 1rem',
          }}
        >
          <Box
            sx={{
              borderRadius: '.5rem',
              border: '1px solid #DEDEDE',
              padding: { xs: '0 1rem', sm: '0 2rem' },
            }}
          >
            {prolongationSteps[prolongationFormNav][currentStep]}
          </Box>
          <RegistrationProgressBarMobile
            progressStep={progressStep}
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            onClick={goBack}
            displayBackButton={false}
          />
        </Box>
      )}
      {loading && <Loading isLoading={loading} />}
    </>
  );
};

export default Prolongation;
